import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCategoriesRequest,
  getNearbyOutletsRequest,
} from "../apis/outlets-apis";

const initialState = {
  outletCategories: {
    loading: false,
    categories: false,
    error: "",
    allCategories: [],
  },
  selectedCategory: "Food & Beverages",
  cardsData: [],
  limit: 16,
  offSet: 0,
  totalCount: 100,
  currentCount: 0,
  loading: false,
  error: "",
};

export const getCategories = createAsyncThunk(
  "outlets/getCategories",
  async (data, { getState, rejectWithValue }) => {
    const { loading } = getState().outlets.outletCategories;
    if (loading) {
      rejectWithValue("Already fetching");
      console.log("rejected");
    }

    try {
      return await getCategoriesRequest();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCardsData = createAsyncThunk(
  "outlets/getCardsData",
  async (requestData, { getState, rejectWithValue }) => {
    const { loading } = getState().outlets;
    if (loading) {
      rejectWithValue("Already fetching");
      console.log("rejected");
    }
    try {
      return await getNearbyOutletsRequest(requestData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
const outletsSlice = createSlice({
  name: "outlets",
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.outletCategories.loading = true;
      state.outletCategories.error = null;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.outletCategories.loading = false;
      state.outletCategories.error = null;
      state.outletCategories.allCategories = action.payload.categories;
      state.selectedCategory = action.payload.categories[0].NAME;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.outletCategories.loading = false;
      state.outletCategories.error = action.payload;
      state.outletCategories.allCategories = [];
    });
    builder.addCase(getCardsData.pending, (state) => {
      state.pending = true;
      state.error = "";
    });
    builder.addCase(getCardsData.fulfilled, (state, action) => {
      state.pending = false;
      state.error = "";
      state.offSet = state.offSet + 1;
      state.currentCount = state.currentCount + state.limit;
      // state.totalCount = action.payload.totalCount;
      state.cardsData = [...state.cardsData, ...action.payload];
    });
    builder.addCase(getCardsData.rejected, (state, action) => {
      state.pending = false;
      state.error = action.payload;
    });
  },
});

export const { setSelectedCategory } = outletsSlice.actions;
export default outletsSlice.reducer;

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import {
  sendLoginOtp,
  openSignup,
  hideAuthComponent,
} from "../../store/slices/authSlice";
import { X } from "lucide-react";
import CustomInput from "./custom-input";

function LogInSendOtp() {
  const dispatch = useDispatch();
  const verifyOtpSchema = z.object({
    phoneNumber: z
      .string()
      .min(1, { message: "Phone Number is required" })
      .regex(/^\d{10}$/, {
        message: "Phone Number should be of 10 digits",
      }),
  });
  const { loading, error } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: validationErrors },
  } = useForm({
    resolver: zodResolver(verifyOtpSchema),
  });

  const onSubmit = (data) => {
    dispatch(sendLoginOtp({ ...data }));
  };
  return (
    <div className="ml-[2%] min-h-screen w-[80%]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex max-w-[700px] justify-between">
          <div className="flex justify-between">
            <div>
              <button
                className="text-customHeading text-2xl hover:scale-110 focus:outline-none"
                onClick={() => {
                  dispatch(hideAuthComponent());
                }}
              >
                <X />
              </button>
              <h2 className="text-customHeading mt-[60px] text-[28px] font-bold leading-[35px]">
                Login
              </h2>
              <div className="mt-[10px] flex">
                <p>or&nbsp;</p>
                <p
                  onClick={() => {
                    dispatch(openSignup());
                  }}
                  className="cursor-pointer text-customTeal"
                >
                  create an account
                </p>
              </div>
              <div className="bg-customHeading mt-[10px] h-[3px] w-[42px]"></div>
            </div>
          </div>
          <img
            src="/authentication.svg"
            alt="authentication"
            className="md:h-[260px] md:w-[186px]"
          />
        </div>
        <div className="mt-[40px] max-w-[600px] md:mt-[20px]">
          {error && <p className="mb-5px text-red-400">{error}</p>}

          <CustomInput
            label={"Phone Number*"}
            placeholder={"Phone Number"}
            type={"text"}
            register={register}
            name={"phoneNumber"}
          />
          {validationErrors.phoneNumber && (
            <p className="mb-5px text-red-400">
              {validationErrors.phoneNumber.message}
            </p>
          )}
          <button
            className={`mt-[5%] h-[44px] w-full cursor-pointer rounded-md md:h-[52px] ${isSubmitting || loading ? "bg-[#EFEFEF] text-[#BDBDBD]" : "bg-customTeal text-[#fcfcfc]"} py-2 text-[16px] text-sm font-medium`}
            disabled={isSubmitting || loading}
          >
            LOGIN
          </button>
        </div>
        <p className="mt-4 text-sm">
          By clicking on Login, I accept the{" "}
          <a href="/#" className="font-bold text-black">
            Terms & Conditions
          </a>{" "}
          &{" "}
          <a href="/#" className="font-bold text-black">
            Privacy Policy
          </a>
          .
        </p>
      </form>
    </div>
  );
}

export default LogInSendOtp;

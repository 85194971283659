import { configureStore } from "@reduxjs/toolkit";
import signupReducer from "./slices/signupSlice.js";
import locationReducer from "./slices/locationSlice";
import authReducer from "./slices/authSlice";
import outletsReducer from "./slices/outletsSlice"

export const store = configureStore({
  reducer: {
    signup: signupReducer,
    auth: authReducer,
    location: locationReducer,
    outlets: outletsReducer
  },
});

export default store;

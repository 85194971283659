import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { registerBusinessFormSchema } from "./register-business-form-schema";
import CustomInput from "./custom-input";
import { saveBusinessToGoogleSheet } from "../../form-submit/save-to-google-sheet/save-to-google-sheet";

function RegisterBusinessForm({ register, handleSubmit, errors }) {
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);

  const onSubmit = (data) => {
    saveBusinessToGoogleSheet(
      data,
      () => {},
      () => {},
    );
  };

  return (
    <form
      className="mt-[10%] w-full rounded-[40px] border-[2px] border-[#29B3D214] bg-[#fff] p-[5%] shadow-sm"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <div className="flex">
          <img
            src="boost-traffic-left.svg"
            alt="left-arrow"
            className="mr-[10px] w-[100px] md:hidden"
          />
          <h2 className="text-customHeading text-[16px] font-semibold md:text-[20px]">
            PERSONAL DETAILS
          </h2>
          <img
            src="boost-traffic-right.svg"
            alt="right arrow"
            className="ml-[10px] w-[100px]"
          />
        </div>

        <div className="flex w-full flex-col items-center justify-center">
          <div className="flex w-full flex-col gap-[10px] md:flex-row">
            <CustomInput
              showLabel
              label={"First Name*"}
              placeholder={"First"}
              type={"text"}
              register={register}
              name={"firstName"}
            />
            <CustomInput
              showLabel
              label={"Last Name*"}
              placeholder={"Last"}
              type={"text"}
              name={"lastName"}
              register={register}
            />
          </div>
          {errors.firstName && (
            <p className="mb-5px text-red-400">{errors.firstName.message}</p>
          )}
          {!errors.firstName && errors.lastName && (
            <p className="mb-5px text-red-400">{errors.lastName.message}</p>
          )}
          <CustomInput
            label={"Designation"}
            showLabel
            placeholder={"Position at outlet / business"}
            type={"text"}
            register={register}
            name={"designation"}
          />
          {errors.designation && (
            <p className="mb-5px text-red-400">{errors.designation.message}</p>
          )}
          <CustomInput
            showLabel
            label={"Mobile Number*"}
            placeholder={"Phone Number"}
            type={"tel"}
            register={register}
            name={"mobileNumber"}
          />
          {errors.mobileNumber && (
            <p className="mb-5px text-red-400">{errors.mobileNumber.message}</p>
          )}
        </div>
      </div>
      <div>
        <div className="mt-[8%] flex">
          <img
            src="boost-traffic-left.svg"
            alt="left-arrow"
            className="mr-[10px] w-[100px] md:hidden"
          />
          <h2 className="text-customHeading text-[16px] font-semibold md:text-[20px]">
            BUSINESS DETAILS
          </h2>
          <img
            src="boost-traffic-right.svg"
            alt="right arrow"
            className="ml-[10px] w-[100px]"
          />
        </div>

        <div className="flex w-full flex-col items-center justify-center">
          <CustomInput
            showLabel
            label={"Business Name*"}
            placeholder={"Business Name"}
            type={"text"}
            register={register}
            name={"businessName"}
          />
          {errors.businessName && (
            <p className="mb-5px text-red-400">{errors.businessName.message}</p>
          )}

          <CustomInput
            showLabel
            label={"Email*"}
            placeholder={"support@themeask.com"}
            type={"text"}
            register={register}
            name={"email"}
          />
          {errors.email && (
            <p className="mb-5px text-red-400">{errors.email.message}</p>
          )}
          <CustomInput
            label={"Business Category*"}
            showLabel
            placeholder={"Food and Beverages / Retail / Electronics,  etc."}
            type={"text"}
            register={register}
            name={"businessCategory"}
          />
          {errors.businessCategory && (
            <p className="mb-5px text-red-400">
              {errors.businessCategory.message}
            </p>
          )}
          <CustomInput
            label={"Address*"}
            showLabel
            placeholder={"Shop No. / Block No."}
            type={"text"}
            register={register}
            name={"address"}
          />
          {errors.address && (
            <p className="mb-5px text-red-400">{errors.address.message}</p>
          )}
          <CustomInput
            label={"Address*"}
            placeholder={"Building Name / Road / Area"}
            type={"text"}
            register={register}
            name={"building"}
          />
          {errors.building && (
            <p className="mb-5px text-red-400">{errors.building.message}</p>
          )}
          <div className="flex w-full flex-col gap-[15px] md:flex-row">
            <CustomInput
              label={"City"}
              placeholder={"City"}
              type={"text"}
              register={register}
              name={"city"}
            />
            <CustomInput
              label={"District"}
              placeholder={"District"}
              type={"text"}
              register={register}
              name={"district"}
            />
          </div>
          {errors.district && (
            <p className="mb-5px text-red-400">{errors.district.message}</p>
          )}
          {!errors.district && errors.city && (
            <p className="mb-5px text-red-400">{errors.city.message}</p>
          )}
          <div className="flex w-full flex-col gap-[15px] md:flex-row">
            <CustomInput
              label={"State"}
              placeholder={"State"}
              type={"text"}
              register={register}
              name={"state"}
            />
            <CustomInput
              label={"Pincode"}
              placeholder={"Pincode"}
              type={"text"}
              register={register}
              name={"pinCode"}
            />
          </div>
          {errors.pinCode && (
            <p className="mb-5px text-red-400">{errors.pinCode.message}</p>
          )}
          {!errors.pinCode && errors.state && (
            <p className="mb-5px text-red-400">{errors.state.message}</p>
          )}
          <CustomInput
            showLabel
            label={"GSTIN*"}
            placeholder={"GST number"}
            type={"text"}
            register={register}
            name={"gstin"}
          />
          {errors.gstin && (
            <p className="mb-5px text-red-400">{errors.gstin.message}</p>
          )}
        </div>
      </div>

      <div className="mt-[5%] w-full">
        <div className="flex gap-[20px]">
          <input
            type="checkbox"
            id="myCheckbox"
            className="checked:bg-[#989898] checked:text-[#989898]"
            onClick={() => setCheck1(!check1)}
          />
          <p className="text-[16px] leading-[20px] text-[#989898]">
            I hereby declare that the information provided is true and correct
            to the best of my knowledge.
          </p>
        </div>
        <div className="flex gap-[20px]">
          <input
            type="checkbox"
            id="myCheckbox"
            className="checked:bg-[#989898] checked:text-[#989898]"
            onClick={() => setCheck2(!check2)}
          />
          <p className="text-[16px] leading-[20px] text-[#989898]">
            I agree to the terms and conditions of Meask for becoming a business
            partner.
          </p>
        </div>
      </div>

      <button
        type="submit"
        className={`mt-[5%] h-[52px] w-full cursor-pointer rounded-md ${check1 && check2 ? "bg-customTeal text-[#fcfcfc]" : "bg-[#EFEFEF] text-[#BDBDBD]"} py-2 text-[16px] text-sm font-medium`}
        onClick={() => handleSubmit(onSubmit)}
        disabled={!check1 || !check2}
      >
        Become a Partner
      </button>
    </form>
  );
}

export default RegisterBusinessForm;

import React, { useEffect, useState } from "react";
import becomePromoter from "../../src/assets/become-promoter.svg";
import becomePromoterMobile from "../assets/become-promoter-mobile.svg";
import ForPromoterForm from "../components/for-promoter/for-promoter-form/for-promoter-form";
import InProgress from "../components/in-progress/in-progress";
import FormSubmitted from "../components/form-submit/form-submitted/form-submitter";
function BecomePromoterPage() {
  const [becomePromoterStyles, setBecomePromoterStyles] = useState({});
  const [inProgress, setProgress] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const updatePromoterBackground = () => {
    if (window.innerWidth >= 780) {
      setBecomePromoterStyles({
        backgroundImage: `url(${becomePromoter})`,
      });
    } else {
      setBecomePromoterStyles({
        backgroundImage: `url(${becomePromoterMobile})`,
      });
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    updatePromoterBackground();

    window.addEventListener("resize", updatePromoterBackground);

    return () => {
      window.removeEventListener("resize", updatePromoterBackground);
    };
  }, []);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error(`Element with ID "${elementId}" 
 not found.`);
    }
  }
  return (
    <div>
      {!inProgress && !isSubmitted && (
        <>
          <div
            className="my-[8%] w-full bg-cover bg-no-repeat pt-[50%] md:mt-0 md:py-[3%] md:pl-[50%] md:pr-[10%]"
            style={becomePromoterStyles}
          >
            <div className="w-full">
              <h1 className="text-customHeading pt-[50px] text-center font-bakbakOne text-[32px] leading-[32px] md:text-left md:text-[56px] md:leading-[56px]">
                Join{" "}
                <span className="font-bakbakOne text-[#29B3D2]">Meask</span>
              </h1>
              <h1 className="text-customHeading text-center font-bakbakOne text-[32px] leading-[32px] md:text-left md:text-[56px] md:leading-[56px]">
                as a Promoter Today
              </h1>
              <p
                className="mt-[2%] text-center text-[16px] font-light leading-[18px] md:text-left md:text-[24px] md:leading-[30px]"
                id={"promoter-form"}
              >
                Register in minutes and start driving brand success with
                exclusive offers and promotions.
              </p>
            </div>
            <div className="hidden w-full md:block">
              <ForPromoterForm
                setProgress={setProgress}
                setSubmitted={setSubmitted}
              />  
            </div>
          </div>
          <div className="w-full md:hidden">
            <ForPromoterForm
              setProgress={setProgress}
              setSubmitted={setSubmitted}
            />
          </div>

          {/* Core Advantages */}
          <div className="mx-[3%] mt-[20%] md:mx-[10%] md:mt-[10%]">
            <div className="flex items-center justify-between md:mt-[5%]">
              <img
                src="boost-traffic-left.svg"
                alt="left-gradient"
                className="w-[92px] md:w-[400px]"
              />
              <div className="flex flex-col items-center justify-center">
                <h4 className="text-customHeading flex flex-row px-[10px] font-bakbakOne text-[22px] leading-tight md:text-[34px]">
                  Core Advantage
                </h4>
              </div>
              <img
                src="boost-traffic-right.svg"
                alt="right-gradient"
                className="w-[92px] md:w-[400px]"
              />
            </div>

            <div className="mt-[7%] flex flex-col items-center justify-between md:flex-row">
              <div className="flex max-w-[320px] flex-col items-center justify-center text-center">
                <img
                  src="core-advantages-1.svg"
                  alt="core-advantages-1"
                  className="size-[60px] md:mb-[10px] md:size-[80px]"
                />
                <h5 className="text-[20px] font-semibold leading-[30px] text-customTextGray">
                  On-Demand payouts, right when you need them
                </h5>
              </div>
              <div className="mt-[10%] flex max-w-[320px] flex-col items-center justify-center text-center md:mt-0">
                <img
                  src="core-advantages-2.svg"
                  alt="core-advantages-2"
                  className="size-[60px] md:mb-[10px] md:size-[80px]"
                />
                <h5 className="text-[20px] font-semibold leading-[30px] text-customTextGray">
                  Enjoy the freedom to work on your own schedule
                </h5>
              </div>
              <div className="mt-[10%] flex max-w-[320px] flex-col items-center justify-center text-center md:mt-0">
                <img
                  src="core-advantages-3.svg"
                  alt="core-advantages-3"
                  className="size-[60px] md:mb-[10px] md:size-[80px]"
                />
                <h5 className="text-[20px] font-semibold leading-[30px] text-customTextGray">
                  Select your preferred location —no more hassle of travel
                </h5>
              </div>
            </div>
          </div>

          {/* Quick and easy enrollment */}
          <div className="mx-[10%] mt-[20%] rounded-[40px] bg-white p-[3%] shadow-[0px_0px_15px_5px_rgba(41,179,210,0.1)] md:mt-[12%] md:pb-[10px]">
            <div className="flex items-center justify-center">
              <img
                src="system-works-left.svg"
                alt="left-gradient"
                className="w-[70px] md:w-[270px]"
              />
              <div className="flex flex-col items-center justify-center text-center">
                <h4 className="text-customHeading px-[10px] font-bakbakOne text-[22px] leading-tight md:text-[30px]">
                  Quick and Easy Enrollment
                </h4>
              </div>
              <img
                src="system-works-right.svg"
                alt="right-gradient"
                className="w-[70px] md:w-[270px]"
              />
            </div>

            <div className="mt-[3%] flex max-w-[1500px] flex-col-reverse items-center justify-center gap-[100px] p-[25px] md:flex-row">
              <img
                src="quick-and-easy-enrollment.svg"
                alt="quick-and-easy-enrollment"
                className="mt-[3%] size-[200px] md:h-[300px] md:w-[400px]"
              />
              <div className="flex w-full items-center justify-start md:w-[60%]">
                <img
                  src="enrollment-steps.svg"
                  alt="enrollment-steps"
                  className="h-[265px] w-[52px]"
                />
                <div className="ml-[30px] flex h-[265px] max-w-[400px] flex-col justify-between">
                  <p className="text-[16px] text-[#7C7C7C] md:text-[20px]">
                    Access Meask anytime, anywhere — download our app for
                    ultimate convenience.
                  </p>

                  <p className="text-[16px] text-[#7C7C7C] md:text-[20px]">
                    Fill in details and documents.
                  </p>
                  <p className="text-[16px] text-[#7C7C7C] md:text-[20px]">
                    Collect joining Kit.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-[10%] mt-[20%] flex flex-col-reverse items-center justify-between p-[3%] md:mt-[10%] md:flex-row md:gap-[70px]">
            <div className="">
              <h1 className="text-customHeading text-center font-bakbakOne text-[40px] leading-[50px] md:text-right md:text-[60px] md:leading-[72px]">
                Join Hands
              </h1>
              <h3 className="text-customHeading text-center font-bakbakOne text-[20px] leading-[28px] md:text-right md:text-[28px]">
                IN ENHANCING SHOPPING EXPERIENCE
              </h3>
            </div>
            <div>
              <img
                src="join-hands.svg"
                alt="join-hands"
                className="h-[80px] md:h-[166px]"
              />
            </div>
          </div>

          <div className="mx-[10%] mt-[20%] flex h-[140px] flex-col items-center justify-center gap-[20px] rounded-[20px] border-[1px] border-[#EFEFEF] p-[3%] md:mt-[10%] md:h-[134px] md:flex-row md:justify-between">
            <h5 className="text-[16px] font-semibold leading-[20px] md:text-[24px] md:leading-[30px]">
              Become a Meask promoter today
            </h5>
            <button
              className="h-[48px] w-[182px] cursor-pointer rounded-md bg-customTeal py-2 text-[16px] text-sm font-medium text-white md:h-[52px] md:w-[320px]"
              onClick={() => {
                scrollToElement("promoter-form");
              }}
            >
              {" "}
              Get Started
            </button>
          </div>
        </>
      )}
      {inProgress && (
        <div className="h-[80vh] w-[100vw]">
          <InProgress />
        </div>
      )}
      {isSubmitted && (
        <div className="h-[80vh] w-[100vw]">
          <FormSubmitted
            heading={"Thank You for Completing the Form"}
            paragraph={
              "Please complete your profile. Our team will review details, and we'll notify you once verification is complete. Thank you for your patience."
            }
            image={"promoter-form-submitted.svg"}
          />
        </div>
      )}
    </div>
  );
}

export default BecomePromoterPage;

import React from "react";

function CustomInput({ label, showLabel, placeholder, name, type, register }) {
  return (
    <div className={`${showLabel ? "mt-[25px]" : "mt-[10px]"} w-full`}>
      <div className="align-start flex w-full flex-col justify-center">
        {showLabel && (
          <p className="mb-[10px] text-[12px] leading-[18px] text-[#989898] md:text-[16px]">
            {label}
          </p>
        )}
        <input
          type={type}
          className={
            "focus h-[56px] border-[1px] border-[#EFEFEF] p-[20px] focus:outline-none"
          }
          placeholder={placeholder}
          {...register(name)}
        />
      </div>
    </div>
  );
}

export default CustomInput;

import { Facebook, Instagram, Linkedin } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleClick = (platformUrl) => {
    window.open(platformUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <footer className="mt-[150px] bg-[#011018] py-10 text-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-col justify-between md:flex-row">
          <div className="mb-8 md:mb-0">
            <Link to="/" className="flex items-center">
              <img
                src="/meask-white-logo.svg"
                alt="Logo"
                className="h-16 w-48"
              />
            </Link>
            <p className="mt-2 text-sm text-footerText">
              © 2024 Meask Solutions Pvt. Ltd.
            </p>
          </div>
          <div className="mb-8 md:mb-0">
            <h4 className="mb-4 text-lg font-bold tracking-widest">
              ABOUT MEASK
            </h4>
            <ul>
              <li className="mb-[15px]">
                <Link
                  to="/about"
                  className="text-footerText hover:text-gray-300"
                >
                  Who We Are
                </Link>
              </li>
              <li className="mb-[15px]">
                <Link
                  to="/services"
                  className="text-footerText hover:text-gray-300"
                >
                  Sitemap
                </Link>
              </li>
              <li className="mb-[15px]">
                <Link
                  to="/team"
                  className="text-footerText hover:text-gray-300"
                >
                  FAQ
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-8 md:mb-0">
            <h4 className="mb-4 text-lg font-bold tracking-widest">
              CONTACT US
            </h4>
            <ul>
              <li className="mb-[15px]">
                <Link
                  to="/contact"
                  className="text-footerText hover:text-gray-300"
                >
                  Help & Support
                </Link>
              </li>
              <li className="mb-[15px]">
                <Link
                  to="/contact"
                  className="text-footerText hover:text-gray-300"
                >
                  Partner with us
                </Link>
              </li>
              <li className="mb-[15px]">
                <Link
                  to="/contact"
                  className="text-footerText hover:text-gray-300"
                >
                  Promote with us
                </Link>
              </li>
            </ul>
            <h4 className="mb-4 mt-[45px] text-lg font-bold tracking-widest md:mt-[90px]">
              LEGAL
            </h4>
            <ul>
              <li className="mb-[15px]">
                <Link
                  to="/terms"
                  className="text-footerText hover:text-gray-300"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="mb-[15px]">
                <Link
                  to="/cookie"
                  className="text-footerText hover:text-gray-300"
                >
                  Cookie Policy
                </Link>
              </li>
              <li className="mb-[15px]">
                <Link
                  to="/privacy"
                  className="text-footerText hover:text-gray-300"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-8 md:mb-0">
            <h4 className="mb-4 text-lg font-bold tracking-widest">
              SOCIAL LINKS
            </h4>
            <ul className="flex space-x-4">
              <li className="mb-[15px]">
                <div className="flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-full bg-white p-2">
                  <Linkedin
                    className="h-8 w-8 text-black"
                    onClick={() => {
                      handleClick(
                        "https://www.linkedin.com/company/measksolutions/",
                      );
                    }}
                  />
                </div>
              </li>
              <li className="mb-[15px] ml-[10px]">
                <div className="flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-full bg-white p-2">
                  <Instagram
                    className="h-8 w-8 text-black"
                    onClick={() => {
                      handleClick("https://www.instagram.com/themeask/");
                    }}
                  />
                </div>
              </li>
              <li className="mb-[15px] ml-[10px]">
                <div className="flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-full bg-white p-2">
                  <Facebook
                    className="h-8 w-8 text-black"
                    onClick={() => {
                      handleClick(
                        "https://www.facebook.com/profile.php?id=61567240714802",
                      );
                    }}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import ButtonJoinNow from "../button-join-now/button-join-now";

function HomePageCard({
  title,
  subtitle,
  handleClick,
  backgroundSvg,
  landingPage,
}) {
  return (
    <div
      className="mt-[3%] flex h-[230px] w-[100%] max-w-[400px] flex-col justify-between rounded-[24px] bg-cover bg-center p-6 text-white lg:mt-0 lg:h-[350px] lg:w-half-minus-6 lg:max-w-[800px]"
      style={{ backgroundImage: `url(${backgroundSvg})` }}
    >
      <div className="w-[75%]">
        <h5 className="font-bakbakOne text-[1.2em] leading-tight lg:text-[26px]">
          {title}
        </h5>
        <p className="mt-2 text-[12px] leading-[15px] lg:text-[18px] lg:leading-[24px]">
          {subtitle}
        </p>
      </div>
      <ButtonJoinNow
        title={landingPage ? "Know More" : "Join Now"}
        handleClick={handleClick}
        className="mt-4"
      />
    </div>
  );
}

export default HomePageCard;

import React from "react";

const HomepageFooterButton = ({ title, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      className="text-customHeading h-[56px] w-full rounded border-[2px] border-[##EFEFEF] bg-white px-4 py-2 font-bold hover:bg-gray-100 md:w-1/2"
    >
      {title}
    </button>
  );
};

export default HomepageFooterButton;

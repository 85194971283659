import React, { useState } from "react";
import LogInSendOtp from "./login-send-otp";
import VerifyOtp from "./verify-otp";
import { useSelector } from "react-redux";

function Login() {
  const { otpSent } = useSelector((state) => state.auth);
  return (
    <div>
      {!otpSent && <LogInSendOtp />}
      {otpSent && <VerifyOtp login />}
    </div>
  );
}

export default Login;

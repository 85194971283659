import React from "react";

function LandingPageCard({
  mainOffer,
  bgImg,
  name,
  address,
  distance,
  mapUrl,
  numberOffer,

}) {
  const maxLength = 30;
  const handleExternalNavigation = (url) => {
    window.open(mapUrl, "_blank");
  };

  return (
    <div >
      <div className="relative z-10 flex flex-col bg-customWhite">
        <div
          style={{ backgroundImage: `url(${bgImg})` }}
          className="relative z-20 aspect-[16/9] w-full rounded-[16px] bg-cover bg-center"
        >
          <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          <h3
            className="absolute bottom-0 pb-4 pl-4 font-bakbakOne text-[18px] leading-[26px] text-customWhite md:text-[22px] md:leading-[30px] lg:text-[24px] lg:leading-[34px]"
            style={{ letterSpacing: "-0.04em" }}
          >
            {mainOffer}
          </h3>
        </div>

        <div className="mt-4 flex w-full px-4">
          <div className="flex w-[65%] flex-col gap-1">
            <h4 className="mb-1 text-[14px] font-semibold leading-[18px] text-customHeading md:text-[16px] md:leading-[20px] lg:text-[18px] lg:leading-[22px]">
              {name}
            </h4>
            <p className="text-[12px] font-light leading-[14px] text-[#7c7c7c] md:text-[14px] md:leading-[16px] lg:text-[16px] lg:leading-[18px]">
              {`${distance} km away`}
            </p>
            <p className="text-[12px] font-light leading-[14px] text-[#7c7c7c] md:text-[14px] md:leading-[16px] lg:text-[16px] lg:leading-[18px]">
              {address.length > maxLength
                ? address.slice(0, maxLength) + "..."
                : address}
            </p>

            <button
              className="mt-2 text-left text-[12px] leading-[14px] text-customHeading underline decoration-1 underline-offset-2 md:mt-1 md:text-[14px] md:leading-[16px] lg:text-[16px] lg:leading-[18px]"
              onClick={() => handleExternalNavigation(mapUrl)}
            >
              Get Direction
            </button>
          </div>

          <div className="flex w-[35%] flex-col items-end">
            <div className="flex flex-col items-center">
              <h3 className="text-[20px] font-bold leading-[24px] text-customTeal md:text-[28px] md:leading-[32px] lg:text-[32px] lg:leading-[36px]">
                {numberOffer}
              </h3>
              <h4 className="text-[12px] font-bold leading-[14px] text-customTeal md:text-[14px] md:leading-[16px] lg:text-[16px] lg:leading-[18px]">
                {numberOffer === 1 ? "OFFER" : "OFFERS"}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageCard;

const API_BASE_URL = "https://api.themeask.com";

const API_URLS = {
  AUTH: {
    sign_up_verify_otp: `${API_BASE_URL}/onboarding/verifyOtp`,
    login_send_otp: `${API_BASE_URL}/onboarding/login`,
    login_verify_otp: `${API_BASE_URL}/onboarding/verifyOtp`,
    sign_up: `${API_BASE_URL}/onboarding/signup`,
    logout: `${API_BASE_URL}/onboarding/logout`,
  },
  LANDINGPAGE: {
    get_categories: `${API_BASE_URL}/cmn/category`,
    get_nearby_outlets: `${API_BASE_URL}/outlet/getNearbyOutlets`,
  },
};

export default API_URLS;

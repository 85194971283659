import { useDispatch } from "react-redux";
import { updateLocation } from "../../store/slices/locationSlice";

async function useSaveLocation() {
  let location = {
    latitude: "",
    longitude: "",
  };
  const dispatch = useDispatch();

  const prevLocation = JSON.parse(localStorage.getItem("currentLocation"));
  if (prevLocation)
    ({ latitude: location.latitude, longitude: location.longitude } =
      prevLocation);

  if (!location.latitude || !location.longitude) {
    location = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        },
      );
    });
  }

  await localStorage.setItem("currentLocation", JSON.stringify(location));
  await dispatch(updateLocation(location));
}
export default useSaveLocation;

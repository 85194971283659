import axiosInstance from "../../utils/axios";
import API_URLS from "../../utils/apiUrls";

export const getCategoriesRequest = async () => {
  const response = await axiosInstance.get(API_URLS.LANDINGPAGE.get_categories);

  return response.data;
};

export const getNearbyOutletsRequest = async (requestData) => {
  const response = await axiosInstance.post(
    `${API_URLS.LANDINGPAGE.get_nearby_outlets}?offSet=${requestData.offSet}&limit=${requestData.limit}`,
    {
      latitude: requestData.latitude,
      longitude: requestData.longitude,
      category: requestData.selectedCategory,
    },
  );
  return response.data;
};

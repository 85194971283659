import React, { useState } from "react";
import CustomInput from "./custom-input";
import { savePromoterToGoogleSheets } from "../../form-submit/save-to-google-sheet/save-to-google-sheet";
export default function RegistrationForm({ setProgress, setSubmitted }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    DOB: "",
    gender: "",
    address: "",
    pincode: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    DOB: "",
    gender: "",
    address: "",
    pincode: "",
  });

  // Handle form data changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validation function
  const validateForm = () => {
    let formErrors = { ...errors };
    let isValid = true;

    // Validate name (required)
    if (!formData.name) {
      formErrors.name = "Name is required";
      isValid = false;
    } else {
      formErrors.name = "";
    }

    // Validate email (valid format)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      formErrors.email = "Please enter a valid email";
      isValid = false;
    } else {
      formErrors.email = "";
    }

    // Validate phone number (should be numeric and length of 10)
    const phoneRegex = /^[0-9]{10}$/;
    if (!formData.phoneNumber) {
      formErrors.phoneNumber = "Phone number is required";
      isValid = false;
    } else if (!phoneRegex.test(formData.phoneNumber)) {
      formErrors.phoneNumber = "Please enter a valid phone number (10 digits)";
      isValid = false;
    } else {
      formErrors.phoneNumber = "";
    }

    // Validate DOB (required and valid date format)
    if (!formData.DOB) {
      formErrors.DOB = "Date of birth is required";
      isValid = false;
    } else {
      formErrors.DOB = "";
    }

    // Validate gender (required)
    if (!formData.gender) {
      formErrors.gender = "Gender is required";
      isValid = false;
    } else {
      formErrors.gender = "";
    }

    // Validate address (required)
    if (!formData.address) {
      formErrors.address = "Address is required";
      isValid = false;
    } else {
      formErrors.address = "";
    }

    // Validate pincode (numeric and length of 6)
    const pincodeRegex = /^[0-9]{6}$/;
    if (!formData.pincode) {
      formErrors.pincode = "Pincode is required";
      isValid = false;
    } else if (!pincodeRegex.test(formData.pincode)) {
      formErrors.pincode = "Please enter a valid pincode (6 digits)";
      isValid = false;
    } else {
      formErrors.pincode = "";
    }

    setErrors(formErrors);
    return isValid;
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form before submitting
    if (validateForm()) {
      // Perform the submit action here (e.g., send data to the server)
      savePromoterToGoogleSheets(formData, setProgress, setSubmitted);
    }
  };

  return (
    <div className="mt-[10%] flex w-full border-[#EEEEEE]">
      <div className="w-full rounded-[40px] bg-white px-[6%] py-[3%]">
        <h2 className="leading=[30px] mb-4 text-center text-xl">
          Register as Meask Promoter
        </h2>
        <form>
          <CustomInput
            label={"Phone Number"}
            type={"tel"}
            value={formData.phoneNumber}
            handleChange={handleInputChange}
            name={"phoneNumber"}
          />
          {errors.phoneNumber && (
            <p className="mb-5px text-red-400">{errors.phoneNumber}</p>
          )}
          <CustomInput
            label={"Name"}
            type={"text"}
            value={formData.name}
            handleChange={handleInputChange}
            name={"name"}
          />
          {errors.name && <p className="mb-5px text-red-400">{errors.name}</p>}
          <CustomInput
            label={"Email"}
            type={"email"}
            value={formData.email}
            handleChange={handleInputChange}
            name={"email"}
          />
          {errors.email && (
            <p className="mb-5px text-red-400">{errors.email}</p>
          )}
          <CustomInput
            label={"DOB"}
            type={"date"}
            value={formData.DOB}
            handleChange={handleInputChange}
            name={"DOB"}
          />
          {errors.DOB && <p className="mb-5px text-red-400">{errors.DOB}</p>}
          <CustomInput
            label={"Gender"}
            type={"text"}
            value={formData.gender}
            handleChange={handleInputChange}
            name={"gender"}
          />
          {errors.gender && (
            <p className="mb-5px text-red-400">{errors.gender}</p>
          )}
          <CustomInput
            label={"Address"}
            type={"text"}
            value={formData.address}
            handleChange={handleInputChange}
            name={"address"}
          />
          {errors.address && (
            <p className="mb-5px text-red-400">{errors.address}</p>
          )}
          <CustomInput
            label={"pincode"}
            type={"text"}
            value={formData.pincode}
            handleChange={handleInputChange}
            name={"pincode"}
          />
          {errors.pincode && (
            <p className="mb-5px text-red-400">{errors.pincode}</p>
          )}
          <button
            type="submit"
            className="mt-[5%] h-[52px] w-full cursor-pointer rounded-md bg-customTeal py-2 text-[16px] text-sm font-medium text-white"
            onClick={handleSubmit}
          >
            Become a Promoter
          </button>
          <p className="mt-4 text-xs text-gray-500">
            By clicking on Submit, I accept the{" "}
            <a href="#" className="font-semibold">
              Terms & Conditions
            </a>{" "}
            &{" "}
            <a href="#" className="font-semibold">
              Privacy Policy
            </a>
          </p>
        </form>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";

function FormSubmitted({ heading, image, paragraph }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center text-center">
        <img
          src={image}
          alt={image}
          className="h-[80px] w-[80px] md:h-[108px] md:w-[108px]"
        />
        <h2 className="text-customHeading my-[20px] font-bakbakOne text-[32px] leading-[32px] md:text-[44px] md:leading-[48px]">
          {heading}
        </h2>
        <p className="max-w-[800px]">{paragraph}</p>
      </div>
    </div>
  );
}

export default FormSubmitted;

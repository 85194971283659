import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function ForBusiness() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="mb-[5%] mt-[35px]">
      <div className="mx-[5%] flex flex-col-reverse items-center justify-between py-[20px] md:mx-[8%] md:flex-row">
        <div className="flex w-full flex-col justify-between">
          <div className="flex flex-col items-center md:items-start">
            {/* <div> */}
            <h2 className="text-customHeading font-bakbakOne text-[32px] leading-[32px] md:max-w-[670px] md:text-[56px] md:leading-[62px]">
              Team up with{" "}
              <span className="font-bakbakOne text-customTeal">Meask</span> with
            </h2>
            <h2 className="text-customHeading max-w-[670px] font-bakbakOne text-[32px] leading-[32px] md:text-[56px] md:leading-[62px]">
              no commission—ever.
            </h2>
          </div>
          <div className="flex items-center justify-center md:justify-start">
            <span className="text-customHeading leading-[32px]">
              Partner with us and keep 100% of your earnings
            </span>
            <span className="text-customHeading hidden leading-[32px] md:flex">
              — no commissions, just growth.
            </span>
          </div>

          <button
            onClick={() => navigate("/for-business-form")}
            className="mx-auto mt-[25px] h-[48px] w-[280px] rounded border-[4px] border-customTeal bg-customTeal text-customWhite md:ml-0 md:mt-[50px] md:h-[56px] md:w-[420px]"
          >
            Register your business
          </button>
        </div>
        <div>
          <img
            src="for-business-page.svg"
            alt="for-business"
            className="h-[172px] w-[182px] md:h-[519px] md:w-[540px]"
          />
        </div>
      </div>
      <div className="mt-[20%] flex items-center justify-center md:mt-[5%]">
        <img
          src="boost-traffic-left.svg"
          alt="left-gradient"
          className="w-[92px] md:w-[390px]"
        />
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-customHeading px-[10px] font-bakbakOne text-[24px] leading-tight md:text-4xl">
            Start boosting your foot traffic today
          </h4>
        </div>
        <img
          src="boost-traffic-right.svg"
          alt="right-gradient"
          className="w-[92px] md:w-[390px]"
        />
      </div>
      <div className="hidden items-center justify-center md:flex">
        <p className="text-center align-middle text-gray-700 md:max-w-xl md:text-left">
          Have your documents prepared for a seamless onboarding process.
        </p>
      </div>

      <div className="mx-[3%] mt-[6%] flex items-center justify-center rounded md:mt-[5%]">
        <div className="w-full max-w-5xl rounded-lg border border-gray-200 px-4 py-8 shadow">
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-4">
            <div className="flex items-center space-x-3">
              <div className="h-4 w-4 rounded-sm bg-[#9645ff]"></div>
              <span className="font-medium text-gray-800">Business Name</span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="h-4 w-4 rounded-sm bg-[#9645ff]"></div>
              <span className="font-medium text-gray-800">PAN card</span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="h-4 w-4 rounded-sm bg-[#9645ff]"></div>
              <span className="font-medium text-gray-800">GSTIN</span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="h-4 w-4 rounded-sm bg-[#9645ff]"></div>
              <span className="font-medium text-gray-800">
                Bank Account Detail
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="h-4 w-4 rounded-sm bg-[#9645ff]"></div>
              <span className="font-medium text-gray-800">
                Top 3 Outlet images
              </span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="h-4 w-4 rounded-sm bg-[#9645ff]"></div>
              <span className="font-medium text-gray-800">Mobile Number</span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="h-4 w-4 rounded-sm bg-[#9645ff]"></div>
              <span className="font-medium text-gray-800">Email Address</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-[3%] mt-[12%] md:mx-[8%]">
        <div className="flex items-center justify-center md:mt-[5%]">
          <img
            src="boost-traffic-left.svg"
            alt="left-gradient"
            className="hidden w-[92px] md:flex md:w-[240px]"
          />
          <div className="flex flex-col items-center justify-center">
            <h4 className="text-customHeading flex flex-row px-[10px] font-bakbakOne text-[24px] leading-tight md:text-[34px]">
              <span className="text-customHeading hidden font-bakbakOne md:flex">
                Why choose &nbsp;
              </span>{" "}
              MEASK as your partner ?
            </h4>
          </div>
          <img
            src="boost-traffic-right.svg"
            alt="right-gradient"
            className="hidden w-[92px] md:flex md:w-[240px]"
          />
        </div>

        <div className="mt-[5%] flex flex-col items-center justify-between md:flex-row">
          <div className="flex max-w-[320px] flex-col items-center justify-center text-center">
            <img
              src="increase-2x-icon.svg"
              alt="increase-2x-icon"
              className="size-[80px]"
            />
            <h5 className="text-[22px] font-semibold leading-[30px] text-customTextGray">
              Increase your revenue by 40%
            </h5>
            <span className="text-[18px] leading-[24px] text-[#7c7c7c]">
              Boost sales and profits with exclusive offers today.
            </span>
          </div>
          <div className="mt-[10%] flex max-w-[320px] flex-col items-center justify-center text-center md:mt-0">
            <img
              src="magnet-icon.svg"
              alt="magnet-icon"
              className="size-[80px]"
            />
            <h5 className="text-[22px] font-semibold leading-[30px] text-customTextGray">
              Attract 15x new customers
            </h5>
            <span className="text-[18px] leading-[24px] text-[#7c7c7c]">
              Enhance recognition and reach more potential customers
              effortlessly.
            </span>
          </div>
          <div className="mt-[10%] flex max-w-[320px] flex-col items-center justify-center text-center md:mt-0">
            <img src="eye-icon.svg" alt="eye-icon" className="size-[80px]" />
            <h5 className="text-[22px] font-semibold leading-[30px] text-customTextGray">
              Strengthen your brand visibility
            </h5>
            <span className="text-[18px] leading-[24px] text-[#7c7c7c]">
              Boost your brand's visibility and drive exponential growth.
            </span>
          </div>
        </div>

        <div className="mt-[10%] flex items-baseline justify-center md:mt-[5%]">
          <img
            src="choose-meask-left.svg"
            alt="left-gradient"
            className="w-[40px] md:w-[100px]"
          />
          <div className="flex flex-col items-center justify-center text-center">
            <h4 className="px-[10px] text-[18px] leading-tight md:px-[75px]">
              With MEASK's insights and offerings elevate your business.
            </h4>
          </div>
          <img
            src="choose-meask-right.svg"
            alt="right-gradient"
            className="w-[40px] md:w-[100px]"
          />
        </div>
      </div>

      <div className="mx-[10%] mt-[12%] rounded-lg bg-white p-6 shadow-[0px_0px_15px_5px_rgba(41,179,210,0.1)]">
        <div className="flex items-center justify-center md:mt-0">
          <img
            src="system-works-left.svg"
            alt="left-gradient"
            className="w-[70px] md:w-[270px]"
          />
          <div className="flex flex-col items-center justify-center text-center">
            <h4 className="text-customHeading px-[10px] font-bakbakOne text-[22px] leading-tight md:text-[30px]">
              How the system works?
            </h4>
          </div>
          <img
            src="system-works-right.svg"
            alt="right-gradient"
            className="w-[70px] md:w-[270px]"
          />
        </div>

        <div className="mt-[4%] flex flex-col-reverse items-center justify-between p-[25px] md:flex-row">
          <img
            src="system-works-image.svg"
            alt="system-works"
            className="mt-[20%] size-[200px] md:mt-[3%] md:h-[500px] md:w-[500px]"
          />
          <div className="flex items-center justify-start">
            <img
              src="system-works-steps.svg"
              alt="system-works"
              className="h-[400px] w-[52px]"
            />
            <div className="ml-[30px] flex h-[400px] max-w-[400px] flex-col justify-between">
              <p className="text-[16px] text-[#7C7C7C] md:text-[22px]">
                Register your business on Meask, the first step toward
                discovery.
              </p>
              <p className="text-[16px] text-[#7C7C7C] md:text-[22px]">
                Meask’s success team connects with you to tailor offerings to
                your needs.
              </p>
              <p className="text-[16px] text-[#7C7C7C] md:text-[22px]">
                Start gaining visibility among social users.
              </p>
              <p className="text-[16px] text-[#7C7C7C] md:text-[22px]">
                Manage your marketing strategies and offers on our online
                dashboard.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForBusiness;

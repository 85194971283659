import React, { useEffect } from "react";

function InProgress() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <img src="in-progress.png" alt="in-progress" className="animate-spin" />
    </div>
  );
}

export default InProgress;

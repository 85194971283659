import React from "react";

const Marquee = () => {
  return (
    <div className="relative z-10 flex h-12 w-full items-center overflow-hidden bg-yellow-300">
      <div className="z-10 flex w-[200%] animate-scrollSmooth items-center justify-center font-bold text-black">
        {Array(50)
          .fill("Coming soon!!!")
          .map((text, index) => (
            <span
              key={index}
              className="mx-8 min-w-[40px] flex-shrink-0 text-lg font-bold md:w-[300px]"
            >
              {text}
            </span>
          ))}
      </div>
    </div>
  );
};

export default Marquee;

import { z } from "zod";

export const registerBusinessFormSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: "First-name and last-name are required" }),
  lastName: z
    .string()
    .min(1, { message: "First-name and last-name are required" }),
  designation: z.string().min(1, { message: "Designation is required" }),
  mobileNumber: z
    .string()
    .min(1, { message: "Mobile is required" })
    .regex(/^\d{10}$/, { message: "Mobile number should be of 10 digits" }),
  businessName: z.string().min(1, { message: "Business name is required" }),
  businessCategory: z
    .string()
    .min(1, { message: "Business category is required" }),
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email("Invalid email address"),
  address: z.string().min(1, { message: "Shop No. / Block No. is required" }),
  building: z
    .string()
    .min(1, { message: "Building Name / Road / Area is required" }),
  city: z.string().min(1, { message: "City and District are required" }),
  district: z.string().min(1, { message: "City and District are required" }),
  state: z.string().min(1, { message: "State and Pincode are required" }),
  pinCode: z
    .string()
    .min(6, { message: "State and Pincode are required" })
    .regex(/^[0-9]{6}$/, "6 Digit pincode is required"),
  gstin: z.string().min(6, { message: "GSTIN is required" }),
});

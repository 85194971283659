import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CircleChevronRight, CircleChevronLeft } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCategory } from "../../store/slices/outletsSlice";

const CategoriesCarousal = () => {
  const dispatch = useDispatch();
  const { pending, allCategories } = useSelector(
    (state) => state.outlets.outletCategories,
  );
  const selectedCategory = useSelector(
    (state) => state.outlets.selectedCategory,
  );
  const state = useSelector((state) => state);
  const slidesToShow = Math.min(allCategories.length, 7);
  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow,
    slidesToScroll: Math.max(slidesToShow - 1, 1),
    arrows: allCategories.length > slidesToShow, // Show arrows only when necessary
    nextArrow: <CustomArrow direction="right" />,
    prevArrow: <CustomArrow direction="left" />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: Math.min(allCategories.length, 5),
          slidesToScroll: Math.max(Math.min(allCategories.length, 5) - 1, 1),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(allCategories.length, 3),
          slidesToScroll: Math.max(Math.min(allCategories.length, 3) - 1, 1),
        },
      },
    ],
  };

  return (
    <div className="relative mx-auto h-[48px] w-full md:h-[60px] md:w-[90%]">
      {!pending && allCategories.length > 0 ? (
        <Slider {...settings}>
          {allCategories.map((category, index) => (
            <div
              key={index}
              className="mx-2 flex w-full max-w-[140px] cursor-pointer flex-col items-center justify-center rounded-lg"
              onClick={() => dispatch(setSelectedCategory(category.NAME))}
            >
              <img
                src={category.icon || "/categories-fallback.svg"}
                alt="category"
                className={`mb-[15px] size-[18px] text-center md:size-[28px] ${selectedCategory === category.NAME ? "text-customHeading" : "text-[#7c7c7c]"}`}
              />
              <p
                className={`max-w-[140px] text-center text-[12px] md:text-[14px] ${selectedCategory === category.NAME ? "text-customHeading" : "text-[#7c7c7c]"}`}
              >
                {category.NAME}
              </p>
              {selectedCategory === category.NAME && (
                <div className="mt-[5px] h-[2px] w-full bg-[#989898]"> </div>
              )}
            </div>
          ))}
        </Slider>
      ) : (
        <div className="flex items-center justify-center text-gray-500">
          No categories available
        </div>
      )}
    </div>
  );
};

const CustomArrow = ({ direction, onClick }) => (
  <button
    onClick={onClick}
    className={`absolute top-1/2 z-10 hidden -translate-y-1/2 transform rounded-full bg-[#fff] md:block ${
      direction === "left" ? "left-2" : "right-2"
    }`}
  >
    {direction === "left" ? (
      <CircleChevronLeft className="text-[#989898]" />
    ) : (
      <CircleChevronRight className="text-[#989898]" />
    )}
  </button>
);

export default CategoriesCarousal;

import React from "react";
import { useSelector } from "react-redux";
import Login from "./login";
import Signup from "./signup";
import VerifyOtp from "./verify-otp";

function Authentication() {
  const sidebarState = useSelector((state) => state.auth["sidebarState"]);

  if (sidebarState === "login")
    return (
      <>
        <Login />
      </>
    );
  else {
    return (
      <>
        <Signup />
      </>
    );
  }
}

export default Authentication;

import React, { useEffect } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSignupOtp,
  verifyOnboardingOtp,
} from "../../store/slices/signupSlice";
import {
  cancelLoginOtp,
  cancelOtp,
  hideAuthComponent,
  openSignup,
  verifyLoginOtp,
} from "../../store/slices/authSlice";
import CustomInput from "./custom-input";
import { ChevronLeft } from "lucide-react";
function VerifyOtp({ login }) {
  const dispatch = useDispatch();
  const { userId, loading, otpVerified, error } = useSelector((state) => {
    return login ? state.auth : state.signup;
  });
  const verifyOtpSchema = z.object({
    otp: z
      .string()
      .min(1, { message: "otp is required" })
      .regex(/^\d{6}$/, {
        message: "otp should be of 6 digits",
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: validationErrors },
  } = useForm({
    resolver: zodResolver(verifyOtpSchema),
  });

  const onSubmit = (otp) => {
    if (login) {
      dispatch(verifyLoginOtp({ ...otp, userId }));
    } else {
      dispatch(verifyOnboardingOtp({ ...otp, userId }));
    }
  };

  return (
    <div className="ml-[2%] min-h-screen w-[80%]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full justify-between md:max-w-[700px]">
          <div className="flex justify-between">
            <div>
              <button
                className="text-customHeading text-2xl hover:scale-110 focus:outline-none"
                onClick={() => {
                  dispatch(login ? cancelLoginOtp() : cancelSignupOtp());
                }}
              >
                <ChevronLeft />
              </button>
              <h2 className="text-customHeading mt-[60px] text-[28px] font-bold leading-[35px]">
                Enter OTP
              </h2>
              <div className="mt-[10px] flex">
                <p>We’ve sent an OTP to your phone number.</p>
              </div>
            </div>
          </div>
          <img src="/otp.svg" alt="otp" className="md:h-[260px] md:w-[186px]" />
        </div>

        <div className="mt-[20px] w-full md:max-w-[600px]">
          {otpVerified && (
            <p className="mb-5px text-green-400">{"OTP verified"}</p>
          )}
          {error && <p className="mb-5px text-red-400">{error}</p>}

          <CustomInput
            label={"One time password"}
            placeholder={"One time password"}
            type={"text"}
            register={register}
            name={"otp"}
          />
          {validationErrors.phoneNumber && (
            <p className="mb-5px text-red-400">
              {validationErrors.otp.message}
            </p>
          )}
          <button
            className={`mt-[5%] h-[44px] w-full cursor-pointer rounded-md md:h-[52px] ${isSubmitting || loading ? "bg-[#EFEFEF] text-[#BDBDBD]" : "bg-customTeal text-[#fcfcfc]"} py-2 text-[16px] text-sm font-medium`}
            disabled={isSubmitting || loading}
          >
            {login ? "Login" : "Signup"}
          </button>
        </div>
        <p className="mt-4 text-sm">
          By clicking on Login, I accept the{" "}
          <a href="/#" className="font-bold text-black">
            Terms & Conditions
          </a>{" "}
          &{" "}
          <a href="/#" className="font-bold text-black">
            Privacy Policy
          </a>
          .
        </p>
      </form>
    </div>
  );
}

export default VerifyOtp;

import axiosInstance from "../../utils/axios";
import API_URLS from "../../utils/apiUrls";

export const sendSignUpOtpRequest = async (userData) => {
  const response = await axiosInstance.post(API_URLS.AUTH.sign_up, {
    ...userData,
  });
 
  return response.data;
};

export const verifySignUpOtpRequest = async (userData) => {
  const response = await axiosInstance.post(API_URLS.AUTH.sign_up_verify_otp, {
    ...userData,
  });
  
  return response.data;
};

export const sendLogInOtpRequest = async (phoneNumber) => {
  const response = await axiosInstance.post(API_URLS.AUTH.login_send_otp, {
    ...phoneNumber,
  });
 
  return response.data;
};

export const verifyLogInOtpRequest = async (userData) => {
  const response = await axiosInstance.post(API_URLS.AUTH.login_verify_otp, {
    ...userData,
  });
  
  return response.data;
};

import React, { useState } from "react";
import {
  Home,
  Megaphone,
  BriefcaseBusiness,
  Menu,
  X,
  UserRound,
} from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import Marquee from "./marquee/marquee";
const Navbar = () => {
  const location = useLocation();
  const currentPage = location.pathname.substring(1) || "/";

  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navItems = [
    {
      name: "Home",
      icon: Home,
      link: "/",
      link2: "",
    },
    {
      name: "Become Promoter",
      icon: Megaphone,
      link: "become-promoter",
      link2: "",
    },
    {
      name: "For Business",
      icon: BriefcaseBusiness,
      link: "for-business",
      link2: "for-business-form",
    },
  ];

  return (
    <nav className="fixed left-0 top-0 z-50 w-full bg-white shadow-md">
      <div className="container flex items-center justify-between px-4 py-4 md:mx-[7%]">
        <div className="flex items-center space-x-2">
          <img
            src="/me-ask-logo.svg"
            alt="Logo"
            className="h-[32px] w-[144px]"
          />
        </div>
        <ul className="hidden space-x-8 md:flex">
          {navItems.map((item) => (
            <li
              key={item.link}
              className={`flex cursor-pointer items-center space-x-2 px-4 py-2 ${
                currentPage === item.link || currentPage === item.link2
                  ? "text-[#29B3D2] underline underline-offset-4"
                  : "text-gray-700 hover:text-gray-900"
              }`}
              onClick={() => navigate(item.link)}
            >
              <item.icon
                className={`h-5 w-5 ${
                  currentPage === item.link || currentPage === item.link2
                    ? "text-[#29B3D2]"
                    : "text-gray-700"
                }`}
              />
              <span>{item.name}</span>
            </li>
          ))}
        </ul>

        {/* Mobile Navigation Links */}
        <div className="mr-[-16px] mt-[-16px] flex align-middle md:hidden">
          {!isMobileMenuOpen && (
            <Menu
              className="mt[16px] mr-[16px]"
              onClick={() => setMobileMenuOpen(true)}
            />
          )}
          {isMobileMenuOpen && (
            <div
              className="z-70 fixed right-0 top-0 flex h-[100vh] w-[75vw] flex-col justify-start bg-white px-[5%] pt-[7%] shadow-lg"
              onClick={() => setMobileMenuOpen(false)}
            >
              <div className="align-middlex flex justify-between">
                <div className="flex flex-col align-middle">
                  <h3 className="text-[20px] font-bold leading-[25px]">Menu</h3>
                  <img src="mobile-menu.svg" alt="Menu" />
                </div>
                <X
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                />
              </div>
              <div className="mt-[20%] flex flex-col justify-start gap-5 align-middle">
                {navItems.map((item) => (
                  <li
                    key={item.link}
                    className={`flex cursor-pointer items-center space-x-2 px-4 py-2 ${
                      currentPage === item.link || currentPage === item.link2
                        ? "text-[#29B3D2] underline underline-offset-4"
                        : "text-gray-700 hover:text-gray-900"
                    }`}
                    onClick={() => navigate(item.link)}
                  >
                    <item.icon
                      className={`mr-[5px] h-5 w-5 ${
                        currentPage === item.link || currentPage === item.link2
                          ? "text-[#29B3D2]"
                          : "text-gray-700"
                      }`}
                    />
                    <span>{item.name}</span>
                  </li>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {currentPage === "/" && !isMobileMenuOpen && <Marquee />}
    </nav>
  );
};

export default Navbar;

import React, { useState } from "react";

function CustomInput({ label, handleChange, type, value, name }) {
  const [isFocus, setFocus] = useState(false);

  return (
    <div className="align-start flex h-[80px] w-full flex-col justify-center border-[1px] border-[#EFEFEF] px-[5%]">
      {isFocus && (
        <label className="text-[12px] leading-[15px] text-[#989898]">
          {label}
        </label>
      )}
      <input
        type={type}
        name={name}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        className={"focus h-[40px] focus:outline-none"}
        placeholder={isFocus ? "" : label}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
}

export default CustomInput;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import BecomePromoterPage from "../BecomePromoter";
import ForBusiness from "../ForBusiness";
import HomePage from "../Home";
import Footer from "../../components/footer/footer";
import ForBusinessFormWithHooks from "../../components/for-business/register-business-form-with-hooks/for-business-form-wrapper";
import LandingPage from "../LandingPage";
import NewNavbar from "../../components/navbar/new-navbar";
import { useEffect, useState } from "react";
function AppRoutes() {
  const [testing, setTesting] = useState(false);

  useEffect(() => {
    if (window.location.href.includes("mvpdemo")) setTesting(true);
  }, [setTesting]);

  return (
    <BrowserRouter>
      <div className="fixed top-0 w-full z-50 bg-[#fff]">
        {testing ? <NewNavbar /> : <Navbar />}
      </div>
      <div className="h-[44px] w-full"></div>
      
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/become-promoter" element={<BecomePromoterPage />} />
        <Route exact path="/for-business" element={<ForBusiness />} />
        <Route
          exact
          path="/for-business-form"
          element={<ForBusinessFormWithHooks />}
        />
        <Route exact path="/mvpdemo/landing-page" element={<LandingPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default AppRoutes;

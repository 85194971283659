import React, { useEffect } from "react";
import CategoriesCarousel from "./categories-carousel";
import { useSelector } from "react-redux";

function Categories() {
  
  return (
    <div className="relative">
      <div className="pointer-events-none absolute left-0 top-0 z-10 h-[50px] w-[12%] bg-gradient-to-r from-[#c1f3f8] to-transparent md:h-[70px] md:w-[10%]"></div>
      <div className="pointer-events-none absolute right-0 top-0 z-10 h-[50px] w-[12%] bg-gradient-to-l from-[#c1f3f8] to-transparent md:h-[70px] md:w-[10%]"></div>
      <CategoriesCarousel />
    </div>
  );
}

export default Categories;

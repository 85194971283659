import React from "react";
import LandingPageCard from "./landing-page-card";
import CardBG from "../../assets/landing-page-card-background.svg";
import { useSelector } from "react-redux";
import { ChevronDown } from "lucide-react";
function NearbyPlaces({ fetchMoreOutlets }) {
  const { cardsData, currentCount, totalCount } = useSelector(
    (state) => state.outlets,
  );
  console.log(cardsData);

  return (
    <div>
      <h3 className="mb-6 max-w-[1200px] text-left text-xl font-semibold">
        Nearby Places
      </h3>

      <div className="relative z-10 grid grid-cols-1 gap-6 p-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {cardsData.map((card, index) => {
          return (
            <LandingPageCard
              key={index}
              mainOffer={card.OFFER || "UPTO 50% OFF"}
              name={card.NAME || "BLR Brewing Co."}
              distance={card.currDist || 2.2}
              address={
                `${card.ADDRESS_LINE_ONE} ${card.ADDRESS_LINE_TWO}` |
                "4th Floor, Neeladri Rd, Electronic City"
              }
              numberOffer={1}
              bgImg={card.PROFILE_PIC_URL || CardBG}
              mapUrl={"https://maps.app.goo.gl/q9ybNuoY1nMc91ZM6"}
            />
          );
        })}
      </div>
      <div>
        {currentCount < totalCount && (
          <div className="flex items-center justify-center gap-[40px] md:mt-[5%]">
            <img
              src="/boost-traffic-left.svg"
              alt="left-gradient"
              className="w-[92px] md:w-[400px]"
            />
            <div
              className="flex cursor-pointer items-center justify-center"
              onClick={() => {
                fetchMoreOutlets();
              }}
            >
              <h4 className="font-outfit mr-[10px] flex flex-row text-[14px] leading-tight text-customTeal md:text-[16px]">
                see more
              </h4>
              <ChevronDown className="text-customTeal" />
            </div>
            <img
              src="/boost-traffic-right.svg"
              alt="right-gradient"
              className="w-[92px] md:w-[400px]"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NearbyPlaces;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendLogInOtpRequest, verifyLogInOtpRequest } from "../apis/auth-apis";

const initialState = {
  userData: {
    email: "",
    phoneNumber: "",
    name: "",
    address: "",
    referral: "",
  },
  otpVerified: false,
  otpSent: false,
  signedIn: false,
  loading: false,
  error: null,
  accessToken: "",
  userId: "",
  sidebarState: "login",
  authComponentVisible: false,
};

export const sendLoginOtp = createAsyncThunk(
  "auth/sendLoginOtp",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      return await sendLogInOtpRequest({ ...phoneNumber });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const verifyLoginOtp = createAsyncThunk(
  "auth/verifyLoginOtp",
  async ({ otp, userId }, { rejectWithValue }) => {
    try {
      return await verifyLogInOtpRequest({ otp, userId });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    openLogin: (state) => {
      state.sidebarState = "login";
    },
    openSignup: (state) => {
      state.sidebarState = "signup";
    },
    showAuthComponent: (state) => {
      state.authComponentVisible = true;
    },
    hideAuthComponent: (state) => {
      state.authComponentVisible = false;
    },
    cancelLoginOtp: (state) => {
      state.loading = false;
      state.userId = "";
      state.otpSent = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendLoginOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendLoginOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.userId = action.payload.userId;
        state.otpSent = true;
      })
      .addCase(sendLoginOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.Message;
      })
      .addCase(verifyLoginOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyLoginOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.otpVerified = true;
        state.signedIn = true;
        state.userData = { ...action.payload.userDetails };
        state.accessToken = action.payload.accessToken;
      })
      .addCase(verifyLoginOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.Message;
      });
  },
});

export const {
  updateUserData,
  openSignup,
  openLogin,
  showAuthComponent,
  hideAuthComponent,
  cancelLoginOtp,
} = authSlice.actions;
export default authSlice.reducer;

import React from "react";
import { Link } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { sendOnboardingOtp } from "../../store/slices/signupSlice";
import { hideAuthComponent, openLogin } from "../../store/slices/authSlice";
import { X } from "lucide-react";
import CustomInput from "./custom-input";

function SignUpSendOtp() {
  const { error, userData, loading } = useSelector((state) => state.signup);
  const dispatch = useDispatch();
  const signupSchema = z.object({
    phoneNumber: z
      .string()
      .min(1, { message: "Phone Number is required" })
      .regex(/^\d{10}$/, { message: "Phone number should be of 10 digits" }),
    name: z.string().min(1, { message: "Name is required" }),
    email: z
      .string()
      .min(1, { message: "Email is required" })
      .email("Invalid email address"),
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: validationErrors },
  } = useForm({
    resolver: zodResolver(signupSchema),
  });

  const onSubmit = (data) => {
    dispatch(sendOnboardingOtp(data));
  };

  return (
    <div className="ml-[2%] min-h-screen w-[80%]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex max-w-[700px] justify-between">
          <div className="flex justify-between">
            <div>
              <button
                className="text-customHeading text-2xl hover:scale-110 focus:outline-none"
                onClick={() => {
                  dispatch(hideAuthComponent());
                }}
              >
                <X />
              </button>
              <h2 className="text-customHeading mt-[60px] text-[28px] font-bold leading-[35px]">
                Signup
              </h2>
              <div className="mt-[10px] flex">
                <p>or&nbsp;</p>
                <p
                  onClick={() => {
                    dispatch(openLogin());
                  }}
                  className="cursor-pointer text-customTeal"
                >
                  login to your account
                </p>
              </div>
              <div className="bg-customHeading mt-[10px] h-[3px] w-[42px]"></div>
            </div>
          </div>
          <img
            src="/authentication.svg"
            alt="authentication"
            className="md:h-[260px] md:w-[186px]"
          />
        </div>
        <div className="mt-[40px] max-w-[600px] md:mt-[20px]">
          {error && <p className="mb-5px text-red-400">{error}</p>}
          <CustomInput
            label={"Phone Number*"}
            placeholder={"Phone Number"}
            type={"text"}
            register={register}
            name={"phoneNumber"}
          />
          <CustomInput
            label={"Name*"}
            placeholder={"Name"}
            type={"text"}
            register={register}
            name={"name"}
          />
          <CustomInput
            label={"Email*"}
            placeholder={"Email"}
            type={"text"}
            register={register}
            name={"email"}
          />
          {validationErrors.phoneNumber && (
            <p className="mb-5px text-red-400">
              {validationErrors.phoneNumber.message}
            </p>
          )}
          <button
            className={`mt-[5%] h-[44px] w-full cursor-pointer rounded-md md:h-[52px] ${isSubmitting || loading ? "bg-[#EFEFEF] text-[#BDBDBD]" : "bg-customTeal text-[#fcfcfc]"} py-2 text-[16px] text-sm font-medium`}
            disabled={isSubmitting || loading}
          >
            CONTINUE
          </button>
        </div>
        <p className="mt-4 text-sm">
          By clicking on Login, I accept the{" "}
          <a href="/#" className="font-bold text-black">
            Terms & Conditions
          </a>{" "}
          &{" "}
          <a href="/#" className="font-bold text-black">
            Privacy Policy
          </a>
          .
        </p>
      </form>
    </div>
  );
}

export default SignUpSendOtp;

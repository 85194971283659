import React, { useEffect, useRef, useState } from "react";
import Categories from "../components/landing-page/categories";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../store/slices/outletsSlice";
import HomePageCard from "../components/homepage/homepage-card/homepage-card";
import ForBusinessCard from "../assets/for-business-card.svg";
import BecomePromoterCard from "../assets/become-promoter-card.svg";
import { useNavigate } from "react-router-dom";
import NearbyPlaces from "../components/landing-page/nearby-places";
import useSaveLocation from "../components/save-location/save-location";
import { getCardsData } from "../store/slices/outletsSlice";

function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useSaveLocation();

  const { limit, offSet, selectedCategory } = useSelector(
    (state) => state.outlets,
  );

  const { longitude, latitude } = useSelector((state) => state.location);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fetchingCardsData, setFetchingCardsData] = useState(false);
  const fetchMoreOutlets = () => {
    if (latitude && longitude && !fetchingCardsData) {
      setFetchingCardsData(true);
      dispatch(
        getCardsData({
          longitude,
          latitude,
          limit,
          offSet,
          selectedCategory,
        }),
      ).finally(() => setFetchingCardsData(false));
    }
  };
  useEffect(() => {
    if (isInitialLoad) {
      dispatch(getCategories());
      if (latitude && longitude) {
        fetchMoreOutlets();
      }
      setIsInitialLoad(false);
    }
  }, [dispatch, latitude, longitude, isInitialLoad]);

  useEffect(() => {
    if (!isInitialLoad && latitude && longitude) {
      fetchMoreOutlets();
    }
  }, [selectedCategory]);

  return (
    <div className="mt-[32px]">
      <div className="mx-[5%] mt-[7%] flex min-h-[380px] max-w-[1200px] flex-col md:mx-auto">
        <h3 className="mb-6 max-w-[1200px] text-left text-xl font-semibold">
          What's new ?
        </h3>
        <div className="flex max-w-[1200px] flex-col items-center justify-between lg:flex-row">
          {/* <div className="flex flex-col gap-6 px-6 md:max-w-5xl md:flex-row"> */}
          <HomePageCard
            title={"Secure Your Spot as a First-Wave Promoter."}
            subtitle={
              "Get ahead as part of our first-wave promoter team. Lock in your exclusive perks by enrolling early!"
            }
            backgroundSvg={BecomePromoterCard}
            handleClick={() => navigate("become-promoter")}
            landingPage
          />
          <HomePageCard
            title={"Exclusive Early Access for Business Partners."}
            subtitle={
              "Unlock special benefits by joining as a business partner today. Early bird access is limited"
            }
            backgroundSvg={ForBusinessCard}
            handleClick={() => navigate("for-business")}
            landingPage
          />
        </div>
      </div>

      <div className="mx-[2%] mt-[7%] md:mx-[8%]">
        <NearbyPlaces fetchMoreOutlets={fetchMoreOutlets} />
      </div>
    </div>
  );
}

export default LandingPage;

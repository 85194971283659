export function savePromoterToGoogleSheets(data, setProgress, setSubmitted) {
  const formURL =
    "https://docs.google.com/spreadsheets/d/1dJuyB0CaiCgLOicIQsXfDWOyMs2lklMSfTIxe1zCfaw/edit?pli=1&gid=0#gid=0";
  const scriptUrl =
    "https://script.google.com/u/0/home/projects/1q2Ht7_MMK26JZNeOxVeEsg-TnauZDZ4L-H9FOokKsoFwYH85SP1FRjfq/edit";
  const savePromoterURL =
    "https://script.google.com/macros/s/AKfycbwGqDYogBGIZDQyoXmt9V2AOMQlIy33WouvEwXl0hSgMtKI2AEoCXtK2cenzw9ORKkWNA/exec";
  setProgress(true);
  const formData = new FormData();
  formData.append("Name", data.name);
  formData.append("Email", data.email);
  formData.append("PhoneNumber", data.phoneNumber);
  formData.append("DOB", data.DOB);
  formData.append("Gender", data.gender);
  formData.append("Address", data.address);
  formData.append("Pincode", data.pincode);

  fetch(savePromoterURL, {
    method: "POST",
    body: formData,
  })
    .then((resp) => {
      setSubmitted(true);
      setProgress(false);
    })
    .catch((err) => {
      setProgress(false);
    });
}

export function saveBusinessToGoogleSheet(data, setInprogress, setSubmitted) {
  const saveBusinessURL =
    "https://script.google.com/macros/s/AKfycbyuSAUIFLYD-9qr9yILqE7eFmULaPY18dJY-CY2M0n9d0MraOT_LZzwy2lJedPXGQYp/exec";
  setInprogress(true);
  const formData = new FormData();
  formData.append("FirstName", data.firstName);
  formData.append("LastName", data.lastName);
  formData.append("Designation", data.designation);
  formData.append("MobileNumber", data.mobileNumber);
  formData.append("BusinessName", data.businessName);
  formData.append("Email", data.email);
  formData.append("BusinessCategory", data.businessCategory);
  formData.append(
    "Address",
    data.address +
      "," +
      data.building +
      "," +
      data.city +
      "," +
      data.district +
      "," +
      data.state,
  );
  formData.append("PinCode", data.pinCode);
  formData.append("GSTIN", data.gstin);

  fetch(saveBusinessURL, {
    method: "POST",
    body: formData,
  })
    .then((resp) => {
      setInprogress(false);
      setSubmitted(true);
    })
    .catch((err) => {
      setInprogress(false);
    });
}
